import {
  OwnUpFloatingModal,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpIcon,
  OwnUpSocialButton
} from '@rategravity/own-up-component-library';
import { FacebookIcon } from '@rategravity/own-up-component-library/icon-library/social-icons/facebook';
import React from 'react';
import { SealSVG } from '../../../images/icons/seal';
import { TwitterSVG } from '../../../images/icons/twitter-logo';
import LinkedInSVG from '../../../images/linkedin-logo.png';
import {
  ConfirmationBody,
  ConfirmationHeader,
  SocialButtonWrappers,
  SocialLogos
} from './elements';

export const ConfirmationModal = ({
  open,
  setOpen
}: {
  open: boolean;
  setOpen: (_: boolean) => void;
}) => (
  <OwnUpFloatingModal open={open} onClose={() => setOpen(false)} title={''}>
    <OwnUpGridContainer variant="slim">
      <OwnUpGridItem xs={12}>
        <OwnUpIcon height="48px" icon={SealSVG} />
      </OwnUpGridItem>
      <OwnUpGridItem xs={12}>
        <ConfirmationHeader variant="h3">Thank you for posting a review!</ConfirmationHeader>
      </OwnUpGridItem>
      <OwnUpGridItem xs={12}>
        <ConfirmationBody variant="body1">
          We value your input. Share your review so everyone else can enjoy it too.
        </ConfirmationBody>
      </OwnUpGridItem>
      <OwnUpGridItem xs={12}>
        <SocialButtonWrappers>
          <OwnUpSocialButton
            icon={FacebookIcon}
            to="https://www.facebook.com/dialog/feed?app_id=226132034107547&display=popup&link=https://www.ownup.com"
          >
            Share
          </OwnUpSocialButton>
          <OwnUpSocialButton
            icon={TwitterSVG}
            to="https://twitter.com/intent/tweet?url=https://www.ownup.com&app_key=WsnKQhTzMZui6KeFNNb5Zpx6LTUnyBzATK31jyWd&redirect=true&text=${content}&via=yotpo"
          >
            Share
          </OwnUpSocialButton>
          <OwnUpSocialButton to="https://www.linkedin.com/sharing/share-offsite/?url=https://yotpo.com/go?reference_name=linkedin_social_share&url=https://www.ownup.com&app_key=WsnKQhTzMZui6KeFNNb5Zpx6LTUnyBzATK31jyWd&redirect=true">
            <SocialLogos src={LinkedInSVG} aria-label="Own up linkedin" />
            Share
          </OwnUpSocialButton>
        </SocialButtonWrappers>
      </OwnUpGridItem>
    </OwnUpGridContainer>
  </OwnUpFloatingModal>
);
