import { ownUpLightTheme } from '@rategravity/own-up-component-library';
import fetch from 'node-fetch';
import React, { useEffect, useState } from 'react';
import { Ankle } from '../components/ankle';
import { Layout } from '../components/layout';
import { ReviewsMultiple } from '../components/reviews-multiple';
import { ReviewSingle } from '../components/reviews-single';
import { Intro } from '../components/reviews/intro';
import { ReviewGrid } from '../components/reviews/reviews-grid';
import { ReviewSingleImageEmilyEvan as ReviewSingleImage } from '../components/static-images/reviews';

export const Reviews = () => {
  const [bottomLine, setBottomLine] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [reviews, setReviews] = useState([]);

  const fetchPage = async (page: number) => {
    const url = `https://api.yotpo.com/v1/widget/${process.env.GATSBY_YOTPO_APP_KEY}/products/yotpo_site_reviews/reviews.json?per_page=6&page=${page}`;
    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    };
    fetch(url, options)
      .then((response) => response.json())
      .then((data) => {
        setReviews(data.response.reviews);
        setBottomLine(data.response.bottomline.total_review);
      })
      .catch((_) => null); // no-op
  };

  useEffect(() => {
    // The effect callback type will not accept a promise of void
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchPage(currentPage);
  }, [currentPage]);

  return (
    <Layout>
      <ReviewSingle
        image={<ReviewSingleImage />}
        largeBody={`“They got us the preapproval in like 10 minutes! They absolutely saved the day.”`}
        names={'Emily & Evan'}
        location="Peabody, MA"
        ariaLabel="Emily and Evan Review OwnUp"
      />
      <Intro />
      <ReviewsMultiple theme={ownUpLightTheme} />
      {reviews.length === 0 ? null : (
        <ReviewGrid
          bottomLine={bottomLine}
          currentPage={currentPage}
          reviews={reviews}
          setCurrentPage={setCurrentPage}
        />
      )}
      <Ankle />
    </Layout>
  );
};

export default Reviews;
