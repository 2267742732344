import {
  OwnUpBody,
  OwnUpDetail,
  OwnUpLargeBodyMedium,
  OwnUpSmallHeadlineMedium,
  OwnUpTextInput,
  PropsWithTheme,
  SLATE_100
} from '@rategravity/own-up-component-library';
import styled from 'styled-components';

export const ButtonWrapper = styled.div`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
`;

export const ConfirmationBody = styled(OwnUpBody)`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;

export const ConfirmationHeader = styled(OwnUpSmallHeadlineMedium)`
  margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(2)}px;
`;

export const InputWrapper = styled.div`
  margin-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
`;

export const ReviewInput = styled(OwnUpTextInput)`
  > div {
    border: 1px solid ${SLATE_100};
  }
  > .MuiFilledInput-underline:not(.Mui-error):before {
    background-color: inherit;
    height: 0;
  }
`;

export const RequiredText = styled(OwnUpDetail)`
  padding: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1.5, 0, 3)};
`;

export const ReviewTitle = styled(OwnUpLargeBodyMedium)`
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;

export const SocialButtonWrappers = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1.5)}px;
  width: 228px;
  margin: auto;
  padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(4)}px;
  ${({ theme }: PropsWithTheme<{}>) => theme.breakpoints.up('md')} {
    margin: unset;
    padding-top: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(5)}px;
  }
`;

export const SocialLogos = styled.img`
  width: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(3)}px;
  margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(0, 1.5, -0.5, 0)};
`;

export const StarsWrapper = styled.div`
  margin: ${({ theme }: PropsWithTheme<{}>) => theme.spacing(1.5, 0, 0, 0)};
`;
