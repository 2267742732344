import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';

export const EvanImage = () => (
  <StaticImage
    src="../../images/evan.png"
    alt="Happy Own Up customer Evan and a friend smile at the camera"
    placeholder="blurred"
    quality={100}
  />
);

export const AndrewImage = () => (
  <StaticImage
    src="../../images/andrew.png"
    alt="Happy Own Up customer Andrew smiles at the camera"
    placeholder="blurred"
    quality={100}
  />
);

export const MichaelMarinaImage = () => (
  <StaticImage
    src="../../images/michael.png"
    alt="Happy Own Up customers Michael and Marina smile at the camera"
    placeholder="blurred"
    quality={100}
  />
);

export const BrennaImage = () => (
  <StaticImage
    src="../../images/brenna.png"
    alt="Home Advisor Brenna smiles at the camera"
    placeholder="blurred"
    quality={100}
  />
);

export const reviewImages = {
  'evan.png': <EvanImage />,
  'andrew.png': <AndrewImage />,
  'michael.png': <MichaelMarinaImage />
};
