import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import EmptyYOTPOStar from '../../../images/icons/yotpo-empty-star.svg';
import FilledYOTPOStar from '../../../images/icons/yotpo-filled-star.svg';

const StarWrapper = styled.div`
  display: flex;
  unicodebidi: bidi-override;
  direction: rtl;
  width: 220px;
`;

const Star = styled.button`
  background-color: transparent;
  border: none;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: 7px;
  cursor: pointer;
  &:hover::before {
    content: url(FilledYOTPOStar);
    position: absolute;
    width: 30px;
    height: 3px;
  }
  &:hover ~ ::before {
    content: url(FilledYOTPOStar);
    position: absolute;
    width: 30px;
    height: 30px;
  }
`;

const selectStar = (score: number | undefined, position: number) => {
  if (score) {
    return score < 6 - position ? EmptyYOTPOStar : FilledYOTPOStar;
  } else {
    return EmptyYOTPOStar;
  }
};

export const ReviewStars = ({
  score,
  setScore
}: {
  score: number | undefined;
  setScore: Dispatch<SetStateAction<number>>;
}) => (
  <StarWrapper>
    {[5, 4, 3, 2, 1].map((star, i) => {
      return (
        <Star key={i} type="button" onClick={() => setScore(star)}>
          <img alt={`rating star ${star}`} src={selectStar(score, i + 1)} />
        </Star>
      );
    })}
  </StarWrapper>
);
