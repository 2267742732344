import { useCallback, useReducer } from 'react';

type ReviewFormState = {
  showForm: boolean;
  modalOpen: boolean;
  formSubmitted: boolean;
};

const initialReviewFormState: ReviewFormState = {
  showForm: false,
  modalOpen: false,
  formSubmitted: false
};

const reviewFormReducer = (
  state: ReviewFormState,
  action: { field: keyof ReviewFormState; value: boolean }
): ReviewFormState => {
  const newState = { ...state, [action.field]: action.value };
  if (action.field !== 'showForm' && !newState.modalOpen && newState.formSubmitted) {
    newState.showForm = false;
  }
  return newState;
};

export const useReviewFormState = () => {
  const [{ showForm, modalOpen, formSubmitted }, dispatch] = useReducer(
    reviewFormReducer,
    initialReviewFormState
  );
  return {
    showForm,
    setShowForm: useCallback(
      (value: boolean) => dispatch({ field: 'showForm', value }),
      [dispatch]
    ),
    modalOpen,
    setModalOpen: useCallback(
      (value: boolean) => dispatch({ field: 'modalOpen', value }),
      [dispatch]
    ),
    formSubmitted,
    setFormSubmitted: useCallback(
      (value: boolean) => dispatch({ field: 'formSubmitted', value }),
      [dispatch]
    )
  };
};
