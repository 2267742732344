import { useTrackingInstance } from '@rategravity/1pt-lib';
import {
  BERRY_100,
  OwnUpFillButtonPrimary,
  OwnUpGridContainer,
  OwnUpGridItem,
  OwnUpGridOffset,
  OwnUpGridWrapper,
  OwnUpSmallBodyMedium
} from '@rategravity/own-up-component-library';
import React, { useState } from 'react';
import { validateEmail } from '../../../modules/form-validation';
import { ReviewStars } from '../../reviews/review-form/review-stars';
import { ConfirmationModal } from '../submit-reviews-form/confirmation';
import { InputWrapper, RequiredText, ReviewInput, ReviewTitle, StarsWrapper } from './elements';

// eslint-disable-next-line max-lines-per-function, abcsize/abcsize
export const SubmitReviewsForm = ({
  modalOpen,
  setModalOpen,
  setFormSubmitted
}: {
  modalOpen: boolean;
  setModalOpen: (_: boolean) => void;
  setFormSubmitted: (_: boolean) => void;
}) => {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const [review, setReview] = useState<string | undefined>(undefined);
  const [score, setScore] = useState(0);
  const [submitError, setSubmitError] = useState(false);
  const [title, setTitle] = useState<string | undefined>(undefined);

  const [emailError, setEmailError] = useState({
    error: 'Please enter a valid email address',
    value: false
  });
  const [nameError, setNameError] = useState({ error: 'Name field cannot be empty', value: false });
  const [reviewError, setReviewError] = useState({
    error: 'Review body cannot be empty',
    value: false
  });
  const [titleError, setTitleError] = useState({
    error: 'Review title cannot be empty',
    value: false
  });
  const [scoreError, setScoreError] = useState({
    error: 'Please enter a star rating for this review',
    value: false
  });
  const tracking = useTrackingInstance();

  const errors = [emailError, nameError, reviewError, titleError, scoreError];
  const foundErrors = errors.filter((error) => error.value === true);

  const checkErrors = () => {
    setEmailError({ ...emailError, value: validateEmail(email) ? false : true });
    setNameError({ ...nameError, value: name ? false : true });
    setReviewError({ ...reviewError, value: review ? false : true });
    setTitleError({ ...titleError, value: title ? false : true });
    setScoreError({ ...scoreError, value: score === 0 ? true : false });
  };

  const handleSubmit = async () => {
    checkErrors();

    if (foundErrors.length < 1) {
      const url = `https://api.yotpo.com/v1/widget/reviews`;
      const options =
        process.env.GATSBY_PROD === 'true'
          ? {
              method: 'POST',
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
              },
              /* eslint-disable @typescript-eslint/naming-convention */
              body: JSON.stringify({
                appkey: process.env.GATSBY_YOTPO_APP_KEY,
                sku: 'yotpo_site_reviews',
                product_title: 'Own Up',
                product_url: 'https://www.ownup.com',
                domain: 'https://www.ownup.com',
                display_name: name,
                email,
                review_content: review,
                review_title: title,
                review_score: score
              })
              /* eslint-enable @typescript-eslint/naming-convention */
            }
          : {};
      await fetch(url, options).then((res) => {
        if (!res.ok) {
          tracking.track('Reviews Form', {
            action: 'Post Review',
            success: 'false',
            'Review Score': score,
            errors: res.statusText
          });
          setSubmitError(true);
        } else {
          tracking.track('Reviews Form', {
            action: 'Post Review',
            success: 'true',
            'Review Score': score
          });
          setSubmitError(false);
          setFormSubmitted(true);
          setModalOpen(true);
        }
      });
    } else {
      tracking.track('Reviews Form', {
        action: 'Post Review',
        success: 'false',
        'Review Score': score,
        error: foundErrors
      });
    }
  };

  return (
    <OwnUpGridWrapper>
      <OwnUpGridContainer variant="slim">
        <OwnUpGridItem xs={12}>
          <ReviewTitle variant="body1">Write a review</ReviewTitle>
        </OwnUpGridItem>
        <OwnUpGridItem xs={12}>
          <RequiredText>* Indicates a required field</RequiredText>
        </OwnUpGridItem>
        <OwnUpGridItem xs={12}>
          <OwnUpSmallBodyMedium variant="body1">* Score:</OwnUpSmallBodyMedium>
          <StarsWrapper>
            <ReviewStars score={score} setScore={setScore} />
            {scoreError.value && (
              <OwnUpSmallBodyMedium variant="body1" style={{ color: BERRY_100 }}>
                {scoreError.error}
              </OwnUpSmallBodyMedium>
            )}
          </StarsWrapper>
        </OwnUpGridItem>
        <OwnUpGridItem xs={12} md={4}>
          <InputWrapper>
            <ReviewInput
              label="* Name:"
              labelPosition="outer"
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
              error={nameError.value}
              helperText={nameError.value ? nameError.error : false}
            />
          </InputWrapper>
        </OwnUpGridItem>
        <OwnUpGridItem xs={12} md={4}>
          <InputWrapper>
            <ReviewInput
              label="* Email:"
              labelPosition="outer"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              error={emailError.value}
              helperText={emailError.value ? emailError.error : false}
            />
          </InputWrapper>
        </OwnUpGridItem>
        <OwnUpGridItem xs={12} md={4}>
          <InputWrapper>
            <ReviewInput
              label="* Title:"
              labelPosition="outer"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              error={titleError.value}
              helperText={titleError.value ? titleError.error : false}
            />
          </InputWrapper>
        </OwnUpGridItem>
        <OwnUpGridItem xs={12}>
          <InputWrapper>
            <ReviewInput
              label="* Review:"
              labelPosition="outer"
              value={review}
              multiline
              maxRows={2}
              onChange={(e) => {
                setReview(e.target.value);
              }}
              error={reviewError.value}
              helperText={reviewError.value ? reviewError.error : false}
            />
          </InputWrapper>
        </OwnUpGridItem>
        <OwnUpGridOffset xs={3} md={0} />
        <OwnUpGridItem xs={6}>
          <InputWrapper>
            <OwnUpFillButtonPrimary component="button" onClick={handleSubmit}>
              Submit Review
            </OwnUpFillButtonPrimary>
            {foundErrors.length > 0 || submitError ? (
              <OwnUpSmallBodyMedium variant="body1" style={{ color: BERRY_100 }}>
                {submitError
                  ? 'There was an error submitting your review. Please try again.'
                  : 'One or more of your answers does not meet the required criteria'}
              </OwnUpSmallBodyMedium>
            ) : null}
          </InputWrapper>
        </OwnUpGridItem>
        <ConfirmationModal open={modalOpen} setOpen={setModalOpen} />
      </OwnUpGridContainer>
    </OwnUpGridWrapper>
  );
};
